.SectionComponent {
  // Add light border if two white
  // sections next to each other.
  .is-white + &.is-white {
    border-top: 1px solid #f0f0f0;
  }
}

.example {
  opacity: 0;
}

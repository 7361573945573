@charset "utf-8";

// COLORS
$primary: #4b9ebf;
$info: #d9c76c;
$success: #4bb543;
$warning: #d9b504;
$danger: #a6121f;
$light: #f5f5f5;
$dark: #363636;

// TEXT
$text: #4a4a4a;
$link: #3273dc;
$body-family: BlinkMacSystemFont, -apple-system, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  'Helvetica', 'Arial', sans-serif;
$title-family: false;
$button-family: false;

// BREAKPOINTS
$gap: 32px;
$tablet: 769px;
$desktop: 960px + (2 * $gap);
$widescreen: 1152px + (2 * $gap);
$fullhd: 1344px + (2 * $gap);
$widescreen-enabled: true;
$fullhd-enabled: false;

// LAYOUT
$section-padding: 3rem 1.5rem;
$section-padding-medium: 6rem 1.5rem;
$section-padding-large: 9rem 1.5rem;

.text-info {
  color: $info;
}

// SEE DOCS FOR MORE:
// https://bit.ly/30UvE5O

// IMPORT BULMA
@import '~bulma/bulma.sass';

.full-page {
  min-height: 100vh;
  padding-top: 50px;
}

.link {
  color: $link;
  cursor: pointer;
}

.mx-1 {
  margin-right: 8px;
  margin-left: 8px;
}

.mx-2 {
  margin-right: 16px;
  margin-left: 16px;
}

.mr-1 {
  margin-right: 8px;
}

.mr-2 {
  margin-right: 16px;
}

.mr-3 {
  margin-right: 32px;
}

.ml-1 {
  margin-left: 8px;
}

.ml-2 {
  margin-left: 16px;
}

.ml-3 {
  margin-left: 32px;
}

.ml-4 {
  margin-left: 48px;
}

.mt-1 {
  margin-top: 8px;
}

.mt-2 {
  margin-top: 16px;
}

.mt-3 {
  margin-top: 32px;
}

.mt-4 {
  margin-top: 64px;
}

.mt-5 {
  margin-top: 100px;
}

.pt-1 {
  padding-top: 8px;
}

.pt-2 {
  padding-top: 16px;
}

.pt-3 {
  padding-top: 32px;
}

.pt-4 {
  padding-top: 64px;
}

.pt-5 {
  padding-top: 100px;
}

.mb-1 {
  margin-bottom: 8px;
}

.mb-2 {
  margin-bottom: 16px;
}

.mb-3 {
  margin-bottom: 32px;
}

.mb-4 {
  margin-bottom: 64px;
}

.mb-5 {
  margin-bottom: 100px;
}

.hide {
  display: none;
}

.text-center {
  text-align: center;
}

.light-text {
  opacity: 0.7;
}

.hide {
  display: none;
}

.btn-no-style {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

img .club-logo {
  width: 200px;
}

@media only screen and (max-width: 600px) {
  .full-page {
    min-height: 100vh;
    padding-top: 0px;
  }
  .container {
    padding: 24px;
  }

  .ant-input {
    font-size: 1rem;
  }
}

@media only screen and (min-width: 600px) {
  .d-mt-1 {
    margin-top: 8px;
  }
  .d-mt-2 {
    margin-top: 16px;
  }
  .d-mt-3 {
    margin-top: 32px;
  }
  .d-mt-4 {
    margin-top: 64px;
  }

  .full-page {
    min-height: 100vh;
    padding-top: 0px;
  }
  .container {
    padding: 24px;
  }

  .navbar-item img {
    max-height: 2.75rem;
  }
}
